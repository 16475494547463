import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { AppState, useAppSelector } from 'state';
import { useAccount as useAccountWagmi } from 'wagmi';

export const useToken = () => {
  const account = useAccount();
  const token = useAppSelector((state: AppState) => state.profile.token);
  if (account) {
    return token;
  }
};

export const useAccount = () => {
  const { address } = useAccountWagmi();
  return address
};

export const useIsLogin = () => {
  const { isConnected } = useAccountWagmi();
  return isConnected
};
