import axios from "axios";
import Loading from "components/Layout/Loading"
import { ENV } from "config";
import React, { useEffect } from "react"
import { useDispatch } from "react-redux";
import { LOGIN, SETTOKEN } from "state/profile/action";

export default function Token() {
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        const response = await axios.post(ENV.API_PUBLIC_SERVER + "/user/login", {
          token,
        })

        // console.log(response.data)
  
        dispatch(LOGIN(response.data.address));
        dispatch(SETTOKEN(token));
      } catch (err) {
        console.error(err);
        window.alert("Login Failed. Please try again.");
      } finally {
        window.location.href = "/";
      }
    }

    f();
  }, [])

  return (
    <Loading></Loading>
  )
}