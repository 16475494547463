import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { arbitrum, mainnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { defineChain } from 'viem'

const chain = defineChain({
  id: 2713973266287000,
  name: 'Guildpay',
  nativeCurrency: { name: 'Guild', symbol: 'GUILD', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://guildpay-2713973266287000-1.jsonrpc.sagarpc.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://guildpay-2713973266287000-1.sagaexplorer.io',
    },
  },
  contracts: {
    // ensRegistry: {
    //   address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    // },
    // ensUniversalResolver: {
    //   address: '0xce01f8eee7E479C928F8919abD53E553a36CeF67',
    //   blockCreated: 19_258_213,
    // },
    multicall3: {
      address: '0xd14Afb5EA30a2D0cB8DcdEA9338E3F04a6604C66',
      blockCreated: 692666,
    },
  },
})

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'd85b1413b438f138cac304bce0281a5d'

// 2. Create wagmiConfig
const metadata = {
  name: '2048',
  description: '2048 Games',
  url: window.location.origin, // origin must match your domain & subdomain
  icons: ['https://2048.finance/image/logo/logo.svg']
}

const chains = [chain] as const
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
})

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}