// @ts-nocheck
import { readContracts } from '@wagmi/core'
import { Config, useReadContracts } from 'wagmi'
import GameViewABI from 'abi/GameViewABI'

export async function fetchBoardAttr(config: Config, tokenIds: (string | number)[]): any[] {
  return await readContracts(config, {
    contracts: tokenIds.map(tokenId => ({
      address: process.env.REACT_APP_GAME_VIEW as `0x${string}`,
      abi: GameViewABI,
      functionName: 'board',
      args: [BigInt(process.env.REACT_APP_GAME_SEASON), BigInt(tokenId)],
    }))
  })
}

export async function fetchBoardNumbers(config: Config, tokenIds: (string | number)[]): any[] {
  return await readContracts(config, {
    contracts: tokenIds.map(tokenId => ({
      address: process.env.REACT_APP_GAME_VIEW as `0x${string}`,
      abi: GameViewABI,
      functionName: 'getBoardNumbers',
      args: [BigInt(process.env.REACT_APP_GAME_SEASON), BigInt(tokenId)],
    }))
  })
}

export async function fetchNumberGeneratorAttr(config: Config, tokenIds: (string | number)[]): any[] {
  return await readContracts(config, {
    contracts: tokenIds.map(tokenId => ({
      address: process.env.REACT_APP_GAME_VIEW as `0x${string}`,
      abi: GameViewABI,
      functionName: 'numberGenerator',
      args: [BigInt(process.env.REACT_APP_GAME_SEASON), BigInt(tokenId)],
    }))
  })
}

export async function fetchNumberGeneratorUpgradeCost(config: Config, tokenIds: (string | number)[]): any[] {
  return await readContracts(config, {
    contracts: tokenIds.map(tokenId => ({
      address: process.env.REACT_APP_GAME_VIEW as `0x${string}`,
      abi: GameViewABI,
      functionName: 'upgradeNumberGeneratorCost',
      args: [BigInt(process.env.REACT_APP_GAME_SEASON), BigInt(tokenId), 1],
    }))
  })
}

export async function fetchEnergyGeneratorAttr(config: Config, tokenIds: (string | number)[]): any[] {
  return await readContracts(config, {
    contracts: tokenIds.map(tokenId => ({
      address: process.env.REACT_APP_GAME_VIEW as `0x${string}`,
      abi: GameViewABI,
      functionName: 'energyGenerator',
      args: [BigInt(process.env.REACT_APP_GAME_SEASON), BigInt(tokenId)],
    }))
  })
}