export default [
  {
    "inputs": [
      {
        "internalType": "contract GameManager",
        "name": "_manager",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "UD60x18",
        "name": "x",
        "type": "uint256"
      }
    ],
    "name": "PRBMath_UD60x18_Log_InputTooSmall",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      }
    ],
    "name": "SeasonNotFound",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "board",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "playCount",
        "type": "uint256"
      },
      {
        "internalType": "uint256[]",
        "name": "numbers",
        "type": "uint256[]"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "number",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "breedCost",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "day",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "energyGenerator",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "generated",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "limit",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getBoardNumbers",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "result",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      }
    ],
    "name": "getTokens",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "parameterController",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "gameReward",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token2048",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token2048E",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenBoard",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenNumberGen",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenEnergyGen",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenNumber",
            "type": "address"
          }
        ],
        "internalType": "struct GameToken",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "manager",
    "outputs": [
      {
        "internalType": "contract GameManager",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "numberGenerator",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "level",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "upgradeCost",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "generated",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "upgradeLevel",
        "type": "uint256"
      }
    ],
    "name": "upgradeNumberGeneratorCost",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "baseLevel",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "upgradeLevel",
        "type": "uint256"
      }
    ],
    "name": "upgradeNumberGeneratorCostLL",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "totalCost",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
] as const