export const colors = {
  PURPLE_LIGHT: '#B254F0',
  PURPLE_DARK: '#09002B',
  PURPLE_BG: '#F7DBF0',
  PURPLE_BORDER: '#271F4E',
  PURPLE_DARK_BG: '#120338',
  GRAY_LIGHT: '#E4E4E4',
  GRAY_LIGHT2: '#F2F2F2',
  GRAY_DARK: '#848484',
  PURPLE_BTN_LIGHT: '#B371FF',
  PURPLE_BTN_LIGHT_0: '#c08bfc',
  PURPLE_BTN_DARK: '#4F2580',
  YELLOW_BINANCE: '#F0B90B',
  GLEEN_PROFIT: '#93C353',
  YELLOW_BORDER: '#FFD04D',
  RED_PROFIT: '#ff7875',
  BLUE: '#40a9ff',
  PINK1: '#fa5496',
  PINK2: 'hsl(336, 96%, 45%)',
  BG: '#140e38',
  TONE: '#EF70E0',
};
