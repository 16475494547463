import styled from 'styled-components';
import { bgButton, imageBg } from 'styling/fast';

export const ButtonImgWrapper = styled.button<{ tone?: string }>`
  width: auto;
  border: 0;
  height: auto;
  font-size: 1.6rem;
  padding: 1rem 4rem;
  color: white;
  border-radius: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-image: ${({ tone }) => `url(${bgButton(tone || 'claim')})`};
  opacity: 0.9;
  transition: all 0.5s;
  ${imageBg}
  
  &:disabled {
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  &:disabled:hover {
    transform: translateY(0%);
  }
`;
