/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useActiveWeb3React from './useActiveWeb3React';
import Web3Token from 'web3-token';
import { useAppDispatch } from 'state';
import { LOGIN, LOGOUT, SETTOKEN } from 'state/profile/action';
import { useAccount, useToken } from 'state/profile/hooks';
import axios from 'axios';
import { ENV } from 'config';

const API = ENV.API_PUBLIC_SERVER;

const useVerifyToken = async () => {
  const dispatch = useAppDispatch();
  const token = useToken();
  const account = useAccount();
  // const { account: web3account, library } = useActiveWeb3React();
  const referal = '';

  // console.log(web3account);

  // useEffect(() => {
  //   if (account && account != web3account) {
  //     dispatch(LOGOUT())
  //   }
  // }, [account, web3account])

  // useEffect(() => {
  //   const generateToken = async () => {
  //     try {
  //       const signer = library.getSigner(account);
  //       const token = await Web3Token.sign(
  //         (msg) => signer.signMessage(msg),
  //         '1d'
  //       );

  //       await axios
  //         .post(`${API}/user/login`, {
  //           token,
  //           referal,
  //         })
  //         .then((res) => {
  //           dispatch(SETTOKEN(token));
  //         });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   const setToken = async () => {
  //     if (token) {
  //       try {
  //         const verify = await Web3Token.verify(token);
  //         if (verify.address.toLowerCase() !== account?.toLowerCase()) {
  //           await generateToken();
  //         }
  //       } catch {
  //         await generateToken();
  //       }
  //     } else {
  //       await generateToken();
  //     }
  //   };

  //   if (account) {
  //     dispatch(LOGIN(account));
  //     setToken();
  //   }
  // }, [account, library]);
};

export default useVerifyToken;
