import { useReadContract } from 'wagmi'
import GameManagerABI from '../abi/GameManagerABI'

export default function useGameSeason(seasonId = parseInt(process.env.REACT_APP_GAME_SEASON!)) {
  const result = useReadContract({
    abi: GameManagerABI,
    address: process.env.REACT_APP_GAME_MANAGER as `0x${string}`,
    functionName: 'getSeason',
    args: [BigInt(seasonId)],
  })

  return result
}