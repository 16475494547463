import React from 'react';
import { InputTextWrapper } from './InputTextStyle';

interface Props {
  value?: string;
  onChange?: (e: any) => any;
  onBlur?: (e: any) => any;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: string;
  maxlength?: string;
  className?: string;
  tone?: string;
  max?: number;
  labelmax?: string;
}

const InputText = (props: Props) => {
  return (
    <InputTextWrapper tone={props.tone}>
      <input
        className={props.className}
        type={props.type}
        style={props.style}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.maxlength && (
        <div className="max_length_place">
          <span>{props.maxlength}</span>
        </div>
      )}
      {props.max && (
        <div
          onClick={() => {
            props.onChange({ target: { value: props.max } });
          }}
          className="max_button"
        >
          <span>Max</span>
        </div>
      )}
      {props.max && (
        <div
          onClick={() => {
            props.onChange({ target: { value: props.max } });
          }}
          className="max"
        >
          {!props.labelmax ? 'Max : ' + props.max : props.labelmax}
        </div>
      )}
    </InputTextWrapper>
  );
};

export default InputText;
