import PopupContext from 'context/Popup';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CgTimer } from 'react-icons/cg';
import { AiOutlineClose, AiOutlineLoading3Quarters } from 'react-icons/ai';
import TransactionImg from 'components/Transaction/TransactionImg';
import { FlexRow } from 'components/box';
import StyledModalContainer from 'components/StyledModalContainer';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';

const PopupBar = () => {
  const location = useLocation();
  const popupCtx = useContext(PopupContext);

  const closeModal = () => {
    if (popupCtx.popup !== 'loading') {
      popupCtx.clear();
      if (popupCtx.content.callBack) {
        popupCtx.content.callBack();
      }
    }
  };

  useEffect(() => {
    popupCtx.clear();
    return () => {
      popupCtx.clear();
    };
  }, [location.pathname]);

  return (
    <React.Fragment>
      <CSSTransition
        in={Boolean(popupCtx.popup)}
        timeout={200}
        classNames="fade"
        unmountOnExit
        mountOnEnter
      >
        <StyledModalContainer tone={popupCtx.tone}>
          <div className="bgDark_modal" onClick={closeModal} />
          <div className={`container_modal`}>
            <div onClick={closeModal} className="exit_btn">
              <AiOutlineClose />
            </div>
            {popupCtx.content.title && popupCtx.popup !== 'loading' && (
              <h1 className="title_text">
                <CgTimer style={{ marginRight: '0.5rem', fontSize: '3rem' }} />
                {popupCtx.content.title}
              </h1>
            )}
            {popupCtx.popup === 'loading' && (
              <h1 className="title_text">
                <AiOutlineLoading3Quarters
                  style={{ marginRight: '2rem', fontSize: '3rem' }}
                  className="spining"
                />
                {popupCtx.content.title || 'Waiting Transaction complete'}
              </h1>
            )}
            <div className="content_modal">
              {!popupCtx.content.noIcon && (
                <TransactionImg type={popupCtx.popup} />
              )}
              <FlexRow
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '1.8rem',
                }}
              >
                {popupCtx.content.text}
              </FlexRow>
              {popupCtx.popup !== 'loading' && (
                <FlexRow style={{ marginTop: '1rem' }}>
                  <Button
                    style={{
                      width: '80%',
                      margin: '0 auto',
                      fontSize: '1.6rem',
                      padding: '0.8rem 0',
                    }}
                    onClick={closeModal}
                    tone={popupCtx.tone}
                    text="Confirm"
                    disabled={false}
                  />
                </FlexRow>
              )}
            </div>
          </div>
        </StyledModalContainer>
      </CSSTransition>
    </React.Fragment>
  );
};
export default PopupBar;
