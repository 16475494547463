import Image from 'components/Image';
import React from 'react';
import styled from 'styled-components';

const TransactionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  .loading {
    width: 10rem;
  }
`;

interface Props {
  type?: 'loading' | 'success' | 'error';
}

const srcCat = {
  loading: '/image/game/headcat-draw.png',
  success: '/image/game/headcat-win.png',
  error: '/image/game/headcat-lose.png',
};

function TransactionImg({ type }: Props) {
  return (
    <TransactionWrapper>
      {type && <Image className="loading" src={srcCat[type]} alt="loading" />}
    </TransactionWrapper>
  );
}

export default TransactionImg;
