import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { colors } from 'colors/colors';
import { nftInfo } from 'config';
import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { FlexColumn, Text } from './box';

export const InfoDetailMap = ({ type }) => {
  return (
    <FlexColumn>
      {nftInfo[type]
        ? nftInfo[type].map((e, i) => {
            return (
              <Text key={`detail-${i}`}>
                <b style={{ color: colors.YELLOW_BINANCE }}>
                  {e.name.toUpperCase()} :
                </b>{' '}
                {e.info}
              </Text>
            );
          })
        : type}
    </FlexColumn>
  );
};

const Wrapper = styled.div`
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  :hover {
    color: ${colors.PURPLE_BTN_LIGHT};
  }
`;

function NftTooltip({
  type,
  placement,
}: {
  type: string;
  placement?: TooltipPlacement;
}) {
  return (
    <Tooltip
      trigger={['click', 'hover']}
      placement={placement || 'bottom'}
      title={<InfoDetailMap type={type} />}
    >
      <Wrapper>
        <AiFillInfoCircle />
      </Wrapper>
    </Tooltip>
  );
}

export default NftTooltip;
