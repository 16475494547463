import React from 'react';
import { LayoutContentWrapper } from './styled';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
}

function LayoutContent({ children, className, style, width }: Props) {
  return (
    <LayoutContentWrapper width={width} style={style} className={className}>
      {children}
    </LayoutContentWrapper>
  );
}

export default LayoutContent;
