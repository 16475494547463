import { ReactNode } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ButtonImgWrapper } from './Styled';

interface Props {
  style?: React.CSSProperties;
  onClick?: (e: any) => any;
  text?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  tone?: string;
  loading?: boolean;
}

const ButtonImg = (props: Props) => {
  return (
    <ButtonImgWrapper
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      style={props.style}
      className={props.className}
      tone={props.tone}
    >
      {props.loading ? (
        <AiOutlineLoading3Quarters className="spining" />
      ) : (
        <div>
          {props.text} {props.children}
        </div>
      )}
    </ButtonImgWrapper>
  );
};

export default ButtonImg;
