import axios from "axios";
import { useCallback } from "react";
import { TransactionReceipt } from "viem";
import { useAccount, usePublicClient, useWriteContract } from "wagmi";

async function requestGas(address: string) {
  try {
    await axios.post(process.env.REACT_APP_SOCKET_API + "/gas/faucet", {
      address,
    })
  } catch (err) {
    console.error(err)
  }
}

export default function useWriteContractAndWait() {
  const { address } = useAccount()
  const { writeContractAsync } = useWriteContract()
  const publicClient = usePublicClient()

  const fn = useCallback(async (parameters: any): Promise<TransactionReceipt> => {
    await requestGas(address)
    const hash = await writeContractAsync({ gas: BigInt(500000), ...parameters })
    return await publicClient.waitForTransactionReceipt({ hash: hash })
  }, [writeContractAsync, address])

  return fn
}