import { colors } from 'colors/colors';
import AppBox from 'components/AppBox';
import {
  FlexColumn,
  FlexRow,
  GridColumn,
  H1TEXT,
  Text,
  TextHover,
} from 'components/box';
import NftAvatar from 'components/NftAvatar';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animationBounce } from 'styling/animation';
import { FaStar } from 'react-icons/fa';
import { nftDetails, nftName } from 'config';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Layout/Loading';
import { apiServer } from 'Api';
import { device } from 'styling/fast';
import BuyButton, { BuyButtonWrapper } from 'components/BuyButton';
import ButtonList from 'components/ButtonList';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { VscChromeClose } from 'react-icons/vsc';
import ButtonImg from 'components/ButtonImg';
import Button from 'components/Button';
import { useAccount } from 'state/profile/hooks';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';

const NumberGeneratorNftWrapper = styled.div`
  width: 100%;
  .container {
    width: 100%;
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
  .nft_img_container {
    place-items: flex-start;
  }
  .nft_id {
    color: white;
    background-color: ${colors.PINK1};
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  .nft_detail_container {
    min-width: 50%;
    margin: 0 auto;
  }
  .nft_wrap {
    margin: 0 auto;
    position: relative;
    border-radius: 0.4rem;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    .nft_img {
      height: 5rem;
    }
    .multiple {
      font-size: 2rem;
      color: white;
      margin: 0 0.5rem;
    }
  }

  .detail_con {
    background-color: rgba(0, 0, 0, 1);
    padding: 2rem;
    border-radius: 0.5rem;
    border: solid 0.1rem ${colors.PURPLE_LIGHT};
  }
  .topic {
    color: ${colors.PURPLE_LIGHT};
    font-weight: 500;
  }
  .item_detail {
    margin: 0 2rem;
  }
  .item_detail:nth-child(1) {
    margin-left: 0;
  }
  .item_detail:last-of-type {
    margin-right: 0;
  }
  .star {
    font-size: 2rem;
    color: ${colors.YELLOW_BINANCE};
    margin-right: 1rem;
  }
  @media ${device.laptop} {
    .container {
      grid-template-columns: 1fr;
    }
  }
`;

function NumberNft() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const account = useAccount();
  const { isConfirmed } = useConfirm();
  const pop = usePopup();

  console.log(account);

  const starArray = [];
  if (data) {
    for (let i = 1; i <= Number(data?.star); i++) {
      starArray.push(i);
    }
  }

  const fetchData = async () => {
    const result = await apiServer.nft.getlistNumber(id);
    if (result.success && result.data) {
      setData(result.data);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function buy() {
    const confirm = await isConfirmed({
      noIcon: true,
      text: (
        <FlexColumn style={{ marginTop: 0, width: '80%' }}>
          <NftAvatar
            style={{ height: '10rem' }}
            type={'number'}
            lv={data.number}
          />
          <Text style={{ textAlign: 'center' }} size="xl">
            Confirm to buy Number:{data.number} X {data.amount} numbers
          </Text>

          <Text
            style={{ textAlign: 'center' }}
            size="xl"
            color={colors.PURPLE_BTN_LIGHT}
          >
            {data.listPrice} BUSD
          </Text>
        </FlexColumn>
      ),
    });
    if (confirm.confirm) {
      const result = await apiServer.nft.buy('number', data._id);
      if (result.success && result.data) {
        pop.success({
          title: 'Transaction',
          text: (
            <FlexColumn style={{ marginTop: 0, width: '80%' }}>
              <NftAvatar
                style={{ height: '10rem' }}
                type={'number'}
                lv={data.number}
              />
              <Text style={{ textAlign: 'center' }} size="xl">
                BUY Number:{data.number} X {data.amount} numbers success!!
              </Text>
            </FlexColumn>
          ),
        });
      } else {
        pop.error({
          title: 'Transaction',
          text: `BUY Number ${data.number} X ${data.amount} numbers Failed! : ${result.message}`,
        });
      }
      fetchData();
    }
  }

  async function unList() {
    const confirm = await isConfirmed({
      noIcon: true,
      text: (
        <FlexColumn style={{ marginTop: 0, width: '80%' }}>
          <NftAvatar
            style={{ height: '10rem' }}
            type={'number'}
            lv={data.number}
          />
          <Text style={{ textAlign: 'center' }} size="xl">
            Confirm to cancel list Number:{data.number} X {data.amount} numbers
          </Text>

          <Text
            style={{ textAlign: 'center' }}
            size="xl"
            color={colors.PURPLE_BTN_LIGHT}
          >
            {data.listPrice} BUSD
          </Text>
        </FlexColumn>
      ),
    });
    if (confirm.confirm) {
      const result = await apiServer.nft.deleteListNumber(data._id);
      if (result.success && result.data) {
        pop.success({
          title: 'Transaction',
          text: (
            <FlexColumn style={{ marginTop: 0, width: '80%' }}>
              <NftAvatar
                style={{ height: '10rem' }}
                type={'number'}
                lv={data.number}
              />
              <Text style={{ textAlign: 'center' }} size="xl">
                Cancel list Number:{data.number} X {data.amount} numbers
                success!!
              </Text>
            </FlexColumn>
          ),
        });
        navigate('/number');
      } else {
        pop.error({
          title: 'Transaction',
          text: `Cancel list Number ${data.number} X ${data.amount} numbers Failed! : ${result.message}`,
        });
      }
    }
  }

  return (
    <React.Fragment>
      {!data ? (
        <Loading />
      ) : (
        <NumberGeneratorNftWrapper>
          <AppBox hasBack opacity={true}>
            <GridColumn className="container">
              <GridColumn className="nft_detail_container">
                <BuyButtonWrapper style={{ minWidth: '50rem' }}>
                  <GridColumn className="nft_img_container">
                    <div className="nft_id">
                      <Text>#{data._id}</Text>
                    </div>
                    <FlexRow className="nft_wrap">
                      <NftAvatar
                        className="nft_img"
                        type={'number'}
                        lv={data.number}
                      />
                      <VscChromeClose className="multiple" />
                      <Text size="xl">{data.amount}</Text>
                    </FlexRow>
                    <Text size="xl">Number : {data.number}</Text>
                    <Text size="xl">Amount : {data.amount}</Text>
                    <Text className="price">
                      PRICE : <span>{data.listPrice || 0}</span> BUSD
                    </Text>
                    <Text size="xl">Owner :{account}</Text>
                    {account?.toLowerCase() !== data.walletAddress ? (
                      <ButtonImg
                        onClick={buy}
                        style={{
                          width: '20rem',
                          margin: '0 auto',
                        }}
                        text={'BUY NOW'}
                      />
                    ) : (
                      <Button
                        onClick={unList}
                        style={{
                          fontSize: '1.8rem',
                          padding: '0.5rem 2rem',
                          margin: '0 auto',
                        }}
                        tone="red"
                      >
                        Cancel List
                      </Button>
                    )}
                  </GridColumn>
                </BuyButtonWrapper>
              </GridColumn>
            </GridColumn>
          </AppBox>
        </NumberGeneratorNftWrapper>
      )}
    </React.Fragment>
  );
}

export default NumberNft;
