import Layout from 'components/Layout';
import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GlobalStyle from 'styles/global';
import ScrollToTop from 'context/ScrollToTop';
import Loading from 'components/Layout/Loading';
import LazyLoading from 'LazyLoading';
import LayoutContent from 'components/Layout/LayoutContent';
import { usePollBlockNumber } from 'state/block/hooks';
import useVerifyToken from 'hooks/useVerifyToken';
import Energy from 'feature/Energy';
import Token from 'feature/Token';

import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import PopupBar from 'components/Popup';
import ConfirmModal from 'components/ConfirmModal';
import Deposit from 'feature/Deposit';
import NumberNft from 'feature/NftDetail/NumberNft';

// import { SocketContext, socket } from './context/socket'

const Landing = LazyLoading(import('feature/landing'));
const MarketPlace = LazyLoading(import('feature/Marketplace'));
const Wallet = LazyLoading(import('feature/Wallet'));
const Mint = LazyLoading(import('feature/Mint'));
const Generate = LazyLoading(import('feature/Generate'));
const Board = LazyLoading(import('feature/Board'));
const Number = LazyLoading(import('feature/Number'));
const Home = LazyLoading(import('feature/Home'));
const Breed = LazyLoading(import('feature/Breed'));
const Multiplayer = LazyLoading(import('feature/Multiplayer/Multiplayer'));
const SinglePlayer = LazyLoading(import('feature/Singleplayer/SinglePlayer'));
const RewardLogs = LazyLoading(import('feature/RewardLogs'));
// const ComingSoon = LazyLoading(import('feature/NotFound/ComingSoon'));
const NotFound = LazyLoading(import('feature/NotFound'));
const BoardDetail = LazyLoading(import('feature/BoardDetail'));
const Airdrop = LazyLoading(import('feature/Airdrop'));

// Nft
const EnergyWorkerNft = LazyLoading(
  import('feature/NftDetail/EnergyWorkerNft')
);
const NumberGeneratorNft = LazyLoading(
  import('feature/NftDetail/NumberGeneratorNft')
);

const GumiNft = LazyLoading(import('feature/NftDetail/GumiNft'));

const App = () => {
  usePollBlockNumber();
  useVerifyToken();

  // useEffect(() => {
  //   setTimeout(() => {
  //     toast.error('HELLO WORLD')
  //   }, 1000)
  // }, [])

  return (
    <React.Fragment>
      <ScrollToTop />
      <GlobalStyle />
      <Layout>
        <LayoutContent>
          <PopupBar />
          <ConfirmModal />
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* redirect */}
              <Route
                path="/setting"
                element={<Navigate to="/setting/board" />}
              />

              <Route path="/" element={<Landing />} />
              <Route path="/token" element={<Token />} />
              <Route path="/multiplayer" element={<Multiplayer />} />
              <Route path="/singleplayer" element={<SinglePlayer />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/number" element={<Number />} />
              <Route path="/marketplace" element={<MarketPlace />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/orderhistory" element={<Wallet />} />
              <Route path="/mint" element={<Mint />} />
              <Route path="/breed" element={<Breed />} />
              <Route path="/generate" element={<Generate />} />
              <Route path="/rewardlogs" element={<RewardLogs />} />
              <Route path="/airdrop" element={<Airdrop />} />
              <Route path="/deposit" element={<Deposit />} />

              <Route
                path="/number_generator/:id"
                element={<NumberGeneratorNft />}
              />
              <Route path="/board/:boardId" element={<BoardDetail />} />
              <Route path="/energy_worker/:id" element={<EnergyWorkerNft />} />
              <Route path="/gumi_powerup/:id" element={<GumiNft />} />
              <Route path="/number/:id" element={<NumberNft />} />

              <Route path="/setting/board" element={<Board />} />
              <Route path="/setting/board/:boardId" element={<BoardDetail />} />
              {/* <Route path="/setting/avatar" element={<Board />} /> */}

              <Route path="/energy" element={<Energy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </LayoutContent>
      </Layout>
    </React.Fragment>
  );
};

export default App;
