import Endpoint from '../Endpoint';

enum APIS {
  PATH = '/entity',
  MARKETPLACE_LIST = '/marketplace/list',
  MARKETPLACE_GET_LISTED = '/marketplace/listed',
  MARKETPLACE_BUY = '/marketplace/buy',
}

export default class NftEndpointed extends Endpoint {
  async getById(type, id) {
    return await this.axiosWrapper
      .get(`${APIS.PATH}/${type}/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async list(type, id, price) {
    return await this.axiosWrapper
      .post(`${APIS.MARKETPLACE_LIST}/${type}/${id}`, { price })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async listNumber(number, amount, price) {
    return await this.axiosWrapper
      .post(`${APIS.MARKETPLACE_LIST}/number/${number}/${amount}`, { price })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async deleteListNumber(id) {
    return await this.axiosWrapper
      .delete(`${APIS.MARKETPLACE_LIST}/number/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getlistNumber(id) {
    return await this.axiosWrapper
      .get(`${APIS.MARKETPLACE_GET_LISTED}/number/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getListed(
    account = undefined,
    type,
    limit = null,
    skip = 0,
    level = null,
    score = null,
    min = null,
    max = null,
    remainEnergey = null,
    sorting = null,
    number = [],
    minAmount = undefined,
    maxAmount = undefined
  ) {
    let filters: any = {};
    let sort: any = {};
    if (level) {
      filters.star = level;
    }
    if (account) {
      filters.walletAddress = account;
    }
    if (score) {
      filters.score = { $gte: score };
    }
    if (max || min) {
      filters.listPrice = {};
    }
    if (min) {
      filters.listPrice.$gte = Number(min);
    }
    if (max) {
      filters.listPrice.$lte = Number(max);
    }
    if (remainEnergey && type === 'energy_worker') {
      filters.remaining = { $gte: Number(remainEnergey) };
    }
    if (sorting !== null) {
      sort.listPrice = sorting;
    }
    if (number.length !== 0 && type === 'number') {
      filters.number = {};
      filters.number.$in = number;
    }

    if ((maxAmount || minAmount) && type === 'number') {
      filters.amount = {};
    }
    if (minAmount && type === 'number') {
      filters.amount.$gte = Number(minAmount);
    }
    if (maxAmount && type === 'number') {
      filters.amount.$lte = Number(maxAmount);
    }
    return await this.axiosWrapper
      .get(`${APIS.MARKETPLACE_GET_LISTED}/${type}`, {
        params: {
          limit,
          skip,
          filters: JSON.stringify(filters),
          sort: JSON.stringify(sort),
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async buy(type, id) {
    return await this.axiosWrapper
      .post(`${APIS.MARKETPLACE_BUY}/${type}/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
