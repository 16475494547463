import { colors } from 'colors/colors';
import { ReactNode } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled from 'styled-components';
import { toneBg } from 'styling/fast';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  style?: React.CSSProperties;
  onClick?: (e: any) => any;
  text?: React.ReactNode;
  disabled?: boolean;
  tone?: string;
  className?: string;
  children?: ReactNode;
  loading?: boolean;
}

export const ButtonWrapper = styled.button<{ tone?: string }>`
  width: auto;
  background: ${({ tone }) => toneBg(tone)};
  border: 0;
  height: auto;
  font-size: 2.4rem;
  padding: 1rem 4rem;
  color: white;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 0.9;
  :hover {
    opacity: 1;
  }
  :disabled {
    background-color: ${colors.GRAY_DARK};
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Button = ({
  className,
  disabled,
  onClick,
  style,
  tone,
  text,
  children,
  loading,
  ...others
}: Props) => {
  return (
    <ButtonWrapper
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
      tone={tone || 'pink'}
      {...others}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="spining" />
      ) : (
        text || children
      )}
    </ButtonWrapper>
  );
};

export default Button;
