const scrollForce = (y: number) => {
  console.log(
    y * parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
  return window.scrollTo(
    0,
    y * parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
};

export default scrollForce;
