import { colors } from 'colors/colors';
import AppBox from 'components/AppBox';
import { FlexRow, GridColumn, Text, TextH2, TextTitle } from 'components/box';
import Button from 'components/Button';
import NftAvatar from 'components/NftAvatar';
import React from 'react';
import styled from 'styled-components';
import { animationBorder } from 'styling/animation';
import { toneColor } from 'styling/fast';

const DepositWrapper = styled.div``;

const DepositItemWrapper = styled.div<{ tone?: string }>`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${colors.BG};
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    font-size: 1.6rem;
  }
  ${({ tone }) =>
    tone
      ? animationBorder(toneColor(tone), '3s')
      : animationBorder(toneColor('gold'), '3s')};
`;

function DepositItem({ tone }) {
  return (
    <DepositItemWrapper tone={tone}>
      <FlexRow>
        <Text size="xl">Galler Board</Text>
        <NftAvatar
          type={'boardfull'}
          lv={'5'}
          style={{ marginLeft: '1rem', width: '5rem' }}
        />
      </FlexRow>
      <Button className="btn" tone={tone}>
        DEPOSIT
      </Button>
    </DepositItemWrapper>
  );
}

function Deposit() {
  return (
    <DepositWrapper>
      <TextTitle>DEPOSIT</TextTitle>
      <AppBox style={{ padding: '2rem' }}>
        <TextH2>NFT</TextH2>
        <GridColumn>
          <DepositItem tone={'blue'} />
        </GridColumn>
      </AppBox>

      <AppBox style={{ padding: '2rem' }}>
        <TextH2>LIQUIDITY</TextH2>
        <GridColumn>
          <DepositItem tone={'gold'} />
        </GridColumn>
      </AppBox>
    </DepositWrapper>
  );
}

export default Deposit;
