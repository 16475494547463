export default [
  {
    "inputs": [],
    "name": "ERC1167FailedCreateClone",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidInitialization",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidStarRate",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotInitializing",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NumberTooLow",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OverQuota",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "UD60x18",
        "name": "x",
        "type": "uint256"
      }
    ],
    "name": "PRBMath_UD60x18_Log_InputTooSmall",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      }
    ],
    "name": "RewardClaimed",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      }
    ],
    "name": "SeasonNotFound",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "BoardMinted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "number",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "BreedNumber",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "boardId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "score",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "ClaimReward",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "EnergyGeneratorMinted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "playCount",
        "type": "uint256"
      }
    ],
    "name": "GameStarted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "day",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "GenerateEnergy",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "day",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "GenerateNumber",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "number",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "NumberAssigned",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "NumberGeneratorMinted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "OwnerMintToken2048",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "OwnerMintToken2048E",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "energyMultiplier",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "baseNumber",
            "type": "uint256"
          },
          {
            "internalType": "uint256[]",
            "name": "starRate",
            "type": "uint256[]"
          }
        ],
        "indexed": false,
        "internalType": "struct SeasonParams",
        "name": "params",
        "type": "tuple"
      }
    ],
    "name": "SeasonParamsUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "seasonStart",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "seasonEnd",
        "type": "uint256"
      }
    ],
    "name": "SeasonUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "parameterController",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "gameReward",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token2048",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "token2048E",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenBoard",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenNumberGen",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenEnergyGen",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tokenNumber",
            "type": "address"
          }
        ],
        "indexed": false,
        "internalType": "struct GameToken",
        "name": "tokens",
        "type": "tuple"
      }
    ],
    "name": "TokenDeployed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "level",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "cost",
        "type": "uint256"
      }
    ],
    "name": "UpgradeNumberGenerator",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "number",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "assignNumberToBoard",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "number",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      }
    ],
    "name": "breedNumber",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "score",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "reward",
        "type": "uint256"
      }
    ],
    "name": "claimReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "generateEnergy",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "generateNumber",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getBoardNumberStorage",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "sessionId",
        "type": "uint256"
      }
    ],
    "name": "getGameSession",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "gameId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "seasonId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "boardId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "timestamp",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "score",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "reward",
            "type": "uint256"
          }
        ],
        "internalType": "struct GameSession",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      }
    ],
    "name": "getSeason",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "seasonId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "seasonStart",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "seasonEnd",
            "type": "uint256"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "parameterController",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "gameReward",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "token2048",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "token2048E",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "tokenBoard",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "tokenNumberGen",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "tokenEnergyGen",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "tokenNumber",
                "type": "address"
              }
            ],
            "internalType": "struct GameToken",
            "name": "tokens",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "energyMultiplier",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "baseNumber",
                "type": "uint256"
              },
              {
                "internalType": "uint256[]",
                "name": "starRate",
                "type": "uint256[]"
              }
            ],
            "internalType": "struct SeasonParams",
            "name": "params",
            "type": "tuple"
          }
        ],
        "internalType": "struct GameSeasonView",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract Randomizer",
        "name": "_randomizer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_parameterCtrlImpl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_gameRewardImpl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc20Impl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc721Impl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc1155Impl",
        "type": "address"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      }
    ],
    "name": "mintBoard",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      }
    ],
    "name": "mintEnergyGenerator",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      }
    ],
    "name": "mintNumberGenerator",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "star",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "mintToken2048",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "mintToken2048E",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "randomizer",
    "outputs": [
      {
        "internalType": "contract Randomizer",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract Randomizer",
        "name": "_randomizer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_parameterCtrlImpl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_gameRewardImpl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc20Impl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc721Impl",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_erc1155Impl",
        "type": "address"
      }
    ],
    "name": "reinitialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_seasonStart",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_seasonEnd",
        "type": "uint256"
      }
    ],
    "name": "setSeason",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_seasonEnd",
        "type": "uint256"
      }
    ],
    "name": "setSeasonEnd",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_seasonId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "energyMultiplier",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "baseNumber",
            "type": "uint256"
          },
          {
            "internalType": "uint256[]",
            "name": "starRate",
            "type": "uint256[]"
          }
        ],
        "internalType": "struct SeasonParams",
        "name": "_params",
        "type": "tuple"
      }
    ],
    "name": "setSeasonParameter",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_seasonStart",
        "type": "uint256"
      }
    ],
    "name": "setSeasonStart",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "startGame",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "seasonId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "upgradeLevel",
        "type": "uint256"
      }
    ],
    "name": "upgradeNumberGenerator",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
] as const