import AxiosWrapper from './AxiosWrapper';
import RankingEndpointed from './model/public/ranking';

export default class ServerPublicService {
  private readonly axiosWrapper: AxiosWrapper;

  readonly ranking: RankingEndpointed;

  constructor(axiosWrapper: AxiosWrapper) {
    this.ranking = new RankingEndpointed(axiosWrapper);
  }
}
