import { FlexRow } from 'components/box';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { boxBg, boxShadow } from 'styling/fast';
import { IoIosArrowBack } from 'react-icons/io';
import { colors } from 'colors/colors';
import { useNavigate } from 'react-router';
import { StyleComponent } from 'types';

const AppboxWrapper = styled.div<{ hasopacity?: any }>`
  width: 80%;
  margin: 4rem auto;
  ${boxBg}
  ${boxShadow()}
  border: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 4rem;
  position: relative;
  background-color: ${({ hasopacity }) =>
    hasopacity ? '#09002ba7' : '#09002B'};
  .back_container {
    color: white;
    font-size: 3rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    cursor: pointer;
    :hover {
      color: ${colors.PURPLE_LIGHT};
    }
  }
`;

interface Props extends StyleComponent {
  children: ReactNode;
  hasBack?: boolean;
  url?: string;
  opacity?: boolean;
  className?: string;
  style?: any;
}

function AppBox(props: Props) {
  const navaigate = useNavigate();
  return (
    <AppboxWrapper
      style={props.style}
      className={props.className}
      hasopacity={props.opacity}
    >
      {props.hasBack && (
        <FlexRow onClick={() => navaigate(-1)} className="back_container">
          <IoIosArrowBack />
        </FlexRow>
      )}
      {props.children}
    </AppboxWrapper>
  );
}

export default AppBox;
