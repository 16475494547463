import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/energy`,
}

export default class EnergyEndpointed extends Endpoint {
  async generate(tokenId, amount) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/generate`, { tokenId, amount })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async setPrice(tokenId, energyPrice) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/set_price`, { tokenId, energyPrice })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
