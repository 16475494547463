import { FlexRow } from 'components/box';
import React, { ReactNode } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CSSTransition } from 'react-transition-group';
import { StyleComponent } from 'types';

interface Props extends StyleComponent {
  children: ReactNode;
  state: any;
}

function LoadingIcon(props: Props) {
  if (typeof props.state !== "undefined") {
    return (
      <FlexRow style={props.style} className={props.className}>
        {props.children}
      </FlexRow>
    );
  }

  return (
    <FlexRow style={props.style} className={props.className}>
      <CSSTransition in={!props.state} timeout={200} classNames="fade">
        <AiOutlineLoading3Quarters className="spining" />
      </CSSTransition>
    </FlexRow>
  );
}

export default LoadingIcon;
