import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/number`,
}

export default class NumberEndpointed extends Endpoint {
  async getBreedCost(config = {}) {
    return await this.axiosWrapper
      .get(`${APIS.PATH}/breed/cost`, config)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async breed(targetNumber, amount) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/breed`, {
        targetNumber,
        amount,
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async generate(tokenId, amount) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/generate`, { tokenId, amount })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async upgrade(tokenId) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/upgrade`, { tokenId })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
