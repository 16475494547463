import { useMediaQuery } from 'react-responsive';
import { device } from 'styling/fast';

const useResponsive = (
  input:
    | 'mobileS'
    | 'mobileM'
    | 'mobileL'
    | 'tablet'
    | 'laptop'
    | 'laptopL'
    | 'desktop'
) => {
  return useMediaQuery({ query: device[input] });
};

export default useResponsive;
