import { apiServer } from 'Api';
import { colors } from 'colors/colors';
import { FlexColumn, FlexRow, Text } from 'components/box';
import ButtonImg from 'components/ButtonImg';
import NftAvatar from 'components/NftAvatar';
import { nftName, nftNameImg } from 'config';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import React from 'react';
import styled from 'styled-components';
import { boxBg } from 'styling/fast';
import { NftType, StyleComponent } from 'types';

export const BuyButtonWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  ${boxBg}
  padding: 1rem;
  border-radius: 1rem;
  .price {
    margin-right: 2rem;
    font-size: 2rem;
    font-weight: 500;
    span {
      color: ${colors.YELLOW_BINANCE};
    }
  }
`;

interface Props extends StyleComponent {
  type: NftType;
  data: any;
  refresh: () => any;
}

function BuyButton(props: Props) {
  // const response = await apiServer.nft.buy(type, id);
  const { isConfirmed } = useConfirm();
  const pop = usePopup();

  async function buy() {
    const confirm = await isConfirmed({
      noIcon: true,
      text: (
        <FlexColumn style={{ marginTop: 0, width: '80%' }}>
          <NftAvatar
            style={{ height: '10rem' }}
            action="win"
            type={props.type === 'board' ? 'boardfull' : nftNameImg[props.type]}
            lv={props.data.star.toString()}
          />
          <Text style={{ textAlign: 'center' }} size="xl">
            Confirm to buy {nftName[props.type]} #Lv{props.data.star}
          </Text>

          <Text
            style={{ textAlign: 'center' }}
            size="xl"
            color={colors.PURPLE_BTN_LIGHT}
          >
            {props.data.listPrice} BUSD
          </Text>
        </FlexColumn>
      ),
    });
    if (confirm.confirm) {
      const result = await apiServer.nft.buy(
        props.type,
        props.type === 'boardfull' || props.type === 'board'
          ? props.data.boardId
          : props.data.tokenId
      );
      if (result.success && result.data) {
        pop.success({
          title: 'Transaction',
          text: (
            <FlexColumn style={{ marginTop: 0, width: '80%' }}>
              <NftAvatar
                style={{ height: '10rem' }}
                action="win"
                type={props.type === 'board' ? 'boardfull' : props.type}
                lv={props.data.star.toString()}
              />
              <Text style={{ textAlign: 'center' }} size="xl">
                BUY {nftName[props.type]} #Lv{props.data.star} success!!
              </Text>
            </FlexColumn>
          ),
        });
      } else {
        pop.error({
          title: 'Transaction',
          text: `BUY ${nftName[props.type]} #Lv${props.data.star} Failed! : ${
            result.message
          }`,
        });
      }
      props.refresh();
    }
  }
  return (
    <BuyButtonWrapper style={props.style} className={props.className}>
      <FlexRow className="container">
        <Text className="price">
          PRICE : <span>{props.data.listPrice || 0}</span> BUSD
        </Text>
        <ButtonImg text={'BUY NOW'} onClick={buy} />
      </FlexRow>
    </BuyButtonWrapper>
  );
}

export default BuyButton;
