import Image from 'components/Image';
import React from 'react';
import { NftType } from 'types';

function NftAvatar({
  className,
  action,
  lv,
  type,
  style,
  onClick,
}: {
  action?: 'start' | 'win' | 'lose' | 'draw';
  className?: string;
  style?: React.CSSProperties;
  lv?:
    | string
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '8'
    | '16'
    | '32'
    | '64'
    | '128'
    | '256'
    | '512'
    | '1024'
    | '2048';
  type: NftType;
  onClick?: (any) => any;
}) {
  if (type === 'board') {
    return (
      <Image
        onClick={onClick}
        className={className}
        alt={'avatar-' + lv}
        src={`/image/nft/board/${lv || '1'}.png`}
      />
    );
  }

  if (type === 'boardfull') {
    return (
      <Image
        onClick={onClick}
        style={style}
        className={className}
        alt={'avatar-' + lv}
        src={`/image/nft/board/boardlv${lv || '1'}.png`}
      />
    );
  }

  if (type === 'number') {
    return (
      <Image
        onClick={onClick}
        style={style}
        className={className}
        alt={'number-' + lv}
        src={`/image/nft/number/${lv || '1'}.gif`}
      />
    );
  }

  if (type === 'gacha') {
    return (
      <Image
        onClick={onClick}
        style={style}
        className={className}
        alt={'board-' + lv}
        src={`/image/nft/avatar/gacha/lv${lv || '1'}.png`}
      />
    );
  }

  if (type === 'dog') {
    return (
      <Image
        onClick={onClick}
        style={style}
        className={className}
        alt={'avatar-dog-' + lv}
        src={`/image/nft/avatar/dog/lv${lv || '1'}.png`}
      />
    );
  }

  return (
    <Image
      onClick={onClick}
      style={style}
      className={className}
      alt={'avatar-avatar-cat' + lv}
      src={`/image/nft/avatar/cat/lv${lv || '1'}_${action || 'start'}.png`}
    />
  );
}

export default NftAvatar;
