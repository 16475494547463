import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/airdrop`,
}

export default class AirdropEndpointed extends Endpoint {
  async get() {
    return await this.axiosWrapper
      .get(`${APIS.PATH}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async claim() {
    return await this.axiosWrapper
      .post(`${APIS.PATH}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
