import AxiosWrapper from './AxiosWrapper';
import BalanceEndpoint from './model/server/balance';
import BoardEndpointed from './model/server/board';
import EnergyEndpointed from './model/server/energy';
import FaucetEndpointed from './model/server/faucet';
import AirdropEndpointed from './model/server/airdrop';
import LogsEndpointed from './model/server/logs';
import MintEndpointed from './model/server/mint';
import NftEndpointed from './model/server/nft';
import NumberEndpointed from './model/server/number';

export default class ServerService {
  private readonly axiosWrapper: AxiosWrapper;

  readonly balance: BalanceEndpoint;
  readonly board: BoardEndpointed;
  readonly number: NumberEndpointed;
  readonly energy: EnergyEndpointed;
  readonly mint: MintEndpointed;
  readonly faucet: FaucetEndpointed;
  readonly airdrop: AirdropEndpointed;
  readonly nft: NftEndpointed;
  readonly logs: LogsEndpointed;

  constructor(axiosWrapper: AxiosWrapper) {
    this.balance = new BalanceEndpoint(axiosWrapper);
    this.board = new BoardEndpointed(axiosWrapper);
    this.number = new NumberEndpointed(axiosWrapper);
    this.energy = new EnergyEndpointed(axiosWrapper);
    this.mint = new MintEndpointed(axiosWrapper);
    this.faucet = new FaucetEndpointed(axiosWrapper);
    this.airdrop = new AirdropEndpointed(axiosWrapper);
    this.nft = new NftEndpointed(axiosWrapper);
    this.logs = new LogsEndpointed(axiosWrapper);
  }
}
