import { colors } from 'colors/colors';
import styled from 'styled-components';
import { device, flexAlign } from 'styling/fast';

export const GenerateWrapper = styled.div`
  .container {
    gap: 2rem;
  }
  .control_container {
    justify-content: flex-end;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  .have_btn {
    width: 10rem;
    height: 7rem;
  }
  .level_container {
    border: 0.3rem solid ${colors.BLUE};
    padding: 1rem;
    border-radius: 1rem;
  }
  .level_button_container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .level_btn {
    width: auto;
    height: auto;
    padding: 0.5rem 2rem;
    margin: 0.5rem;
  }
  .gacha_container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 3rem;
  }
  @media ${device.mobileL} {
    .control_container {
      align-items: flex-start;
    }
    .level_container {
      align-items: flex-start;
    }
  }
`;

export const GachaponWrapper = styled.div`
  width: 100%;
  border-radius: 2rem;
  padding: 1rem;
  display: grid;
  grid-template-rows: 30rem auto auto;
  background-color: ${colors.PURPLE_DARK_BG};
  border: solid 0.2rem ${colors.TONE};
  position: relative;
  padding-top: 3rem;
  .topic {
    position: absolute;
    height: 5rem;
    width: 17rem;
    background-color: ${colors.TONE};
    border-radius: 2rem 0 2rem 0;
    top: 0;
    left: 0;
    transform: translateX(-0.2rem) translateY(-0.2rem);
    display: flex;
    ${flexAlign}
    font-size: 2.5rem;
    font-weight: 500;
    color: ${colors.PURPLE_DARK};
  }
  .gacha_img {
    width: 100%;
    height: 100%;
  }
  h2 {
    color: ${colors.TONE};
    text-align: center;
  }
  .time_container {
    height: 6.2rem;
    display: flex;
    ${flexAlign}
    h3 {
      font-size: 1.8rem;
    }
    button {
      font-size: 1.8rem;
    }
  }
`;
