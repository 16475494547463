import dis from 'disable-scroll';

export const disableScroll = () => {
  // document.body.className = 'enableScroll';
  // dis.on();
  document.body.style.overflowY = 'hidden';
};

export const enableScroll = () => {
  // document.body.className = '';
  // dis.off();
  document.body.style.overflowY = 'overlay';
};
