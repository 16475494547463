import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/entity/board`,
  PATHBOARD = `/entity/board`,
  ACTION = `/board`,
}

export default class BoardEndpointed extends Endpoint {
  async getById(id: any) {
    return await this.axiosWrapper
      .get(`${APIS.PATH}/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async removeNumber(payload) {
    return await this.axiosWrapper
      .post(`${APIS.ACTION}/remove_number`, payload)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async addNumber(payload) {
    return await this.axiosWrapper
      .post(`${APIS.ACTION}/add_number`, payload)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
