import { colors } from 'colors/colors';
import { FlexRow, SocialButton } from 'components/box';
import React, { ReactChild } from 'react';

import { AiOutlineMedium } from 'react-icons/ai';
import {
  FaYoutube,
  FaTwitter,
  FaTelegramPlane,
  FaDiscord,
} from 'react-icons/fa';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import styled from 'styled-components';
import scrollForce from 'utils/scrollForce';

const SocialListWrapper = styled.div`
  position: relative;
  .icon_social {
    margin-right: 1rem;
  }
  .up_icon {
    cursor: pointer;
    font-size: 3rem;
    margin: 0 auto;
    color: white;
    :hover {
      color: ${colors.PURPLE_LIGHT};
    }
  }
  .vertical {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    .icon_social {
      margin-right: 0;
    }
    z-index: 10;
  }
`;

function Social({
  link,
  imageUrl,
  icon,
}: {
  link: string;
  imageUrl: string;
  icon: ReactChild;
}) {
  return (
    <a target="_blank" rel="noreferrer" href={link}>
      <SocialButton className="icon_social" image={imageUrl}>
        {icon}
      </SocialButton>
    </a>
  );
}

const SocialList = ({
  className,
  vertical,
}: {
  className?: string;
  vertical?: Boolean;
}) => {
  const classNameParse = className || '';
  return (
    <SocialListWrapper>
      <FlexRow className={classNameParse + `${vertical ? ' vertical' : ''}`}>
        {vertical && (
          <IoIosArrowDropupCircle
            onClick={() => scrollForce(0)}
            className="up_icon"
          />
        )}
        <Social
          imageUrl={'/image/social/darkblue.webp'}
          link={'https://twitter.com/2048Finance'}
          icon={<FaTwitter />}
        />
        <Social
          imageUrl={'/image/social/yellow.webp'}
          link={'https://discord.gg/cHc7BQuFFS'}
          icon={<FaDiscord />}
        />
        <Social
          imageUrl={'image/social/pink.webp'}
          link={'https://medium.com/@2048.finance'}
          icon={<AiOutlineMedium />}
        />
        <Social
          imageUrl={'/image/social/red.webp'}
          link={'https://www.youtube.com/channel/UCRG9-yoxMeFl5ZzvzW5FBFQ'}
          icon={<FaYoutube />}
        />
        <Social
          imageUrl={'/image/social/blue.webp'}
          link={'https://t.me/G2048Finance'}
          icon={<FaTelegramPlane />}
        />
      </FlexRow>
    </SocialListWrapper>
  );
};

export default SocialList;
