import { FlexRow } from 'components/box';
import Image from 'components/Image';
import { DEFAULT_META, getCustomMeta } from 'config/meta';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import SocialList from './SocialList';
import { FooterWrapper, LayoutWrapper } from './styled';

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { pathname } = useLocation();
  const pageMeta = getCustomMeta(pathname);
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

function Footer() {
  return (
    <FooterWrapper>
      <FlexRow className="container">
        <Link className="link_img" to={'/'}>
          <Image className="img_footer" src="/image/logo/logo.svg" alt="logo" />
        </Link>
        <SocialList className={'social_contain'} />
      </FlexRow>
    </FooterWrapper>
  );
}

interface Props {
  children: React.ReactNode;
}
function Layout(props: Props) {
  return (
    <LayoutWrapper>
      <PageMeta />
      <Navbar />
      <div className="content">{props.children}</div>
      <Footer />
    </LayoutWrapper>
  );
}

export default Layout;
