import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/balance`,
}

export default class BalanceEndpoint extends Endpoint {
  async get() {
    return await this.axiosWrapper
      .get(`${APIS.PATH}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getByType(type, limit = null, skip = 0) {
    if (type === 'all') {
      return await this.axiosWrapper
        .get(`${APIS.PATH}`)
        .then((res) => this.axiosWrapper.interceptor<any>(res))
        .catch((err) => this.axiosWrapper.interceptor<any>(err));
    }
    return await this.axiosWrapper
      .get(`${APIS.PATH}/${type}`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
