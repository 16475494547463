import React, { useState } from 'react';
import useAuth from 'hooks/useAuth';
// import { useTranslation } from 'context/Localization';
import {
  ConnectWalletButtonWrapper,
  LogoutWrapper,
  PopUpConnectWrapper,
} from './ConnectWalletButtonStyled';
import ItemWalletConnect from './ItemWalletConnect';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import ModalPopup from '../ModalPopup/ModalPopup';
import { disableScroll, enableScroll } from 'utils/scrollBody';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from 'components/Button';
import { useAccount, useDisconnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';

interface Props {
  style?: React.CSSProperties;
  text?: string;
}

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

const ConnectWalletButton = (props: Props) => {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  // const { account: web3account, active } = useActiveWeb3React();
  const [logoutPop, setLogoutPop] = useState(false);
  // const { t } = useTranslation();
  const [copy, setCopy] = useState(false);
  const { open } = useWeb3Modal()
  // const { onPresentConnectModal } = useWalletModal(login, logout, t);

  const handleClick = (e) => {
    e.stopPropagation();
    if (isConnected) {
      disableScroll();
      setLogoutPop(true);
    } else {
      open()
    }
  };

  const onCLickCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };

  return (
    <ConnectWalletButtonWrapper>
      {!isConnected ? (
        <button style={props.style} className="login_btn" onClick={handleClick}>
          {props.text ? props.text : 'Connect Wallet'}
        </button>
      ) : (
        <button className="login_btn" onClick={handleClick}>
          {address
            ? address.slice(0, 4) +
              '....' +
              address.slice(address.length - 4, address.length)
            : 'Connect Wallet'}
        </button>
      )}

      <ModalPopup
        show={logoutPop}
        onClose={() => {
          enableScroll();
          setLogoutPop(false);
        }}
        title="Your wallet"
      >
        <LogoutWrapper>
          <div className="logout_container">
            <h2>{address}</h2>
            <div className="link_contain">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://bscscan.com/address/${address}`}
              >
                <div>
                  <span>View on BscScan</span>
                  <FiExternalLink />
                </div>
              </a>
              <CopyToClipboard
                text={address ? address : ''}
                onCopy={onCLickCopy}
              >
                <div className="copy_contain">
                  <div>
                    <span>Copy Address</span>
                    <MdContentCopy />
                  </div>
                </div>
              </CopyToClipboard>
              {copy && (
                <div className="copy_success">
                  <span>Copied</span>
                </div>
              )}
            </div>
            <div className="logout_btn">
              <Button
                disabled={false}
                onClick={() => {
                  setLogoutPop(false);
                  disconnect();
                }}
                style={{ margin: '0 auto' }}
                text={'Logout'}
              />
            </div>
          </div>
        </LogoutWrapper>
      </ModalPopup>
    </ConnectWalletButtonWrapper>
  );
};

export default ConnectWalletButton;
