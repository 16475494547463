import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import './styles/helpers.scss';
import './styles/fix.css';
import Providers from './context/Providers';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.hydrate(
  <BrowserRouter>
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>,
  document.getElementById('root')
);

