/* eslint-disable array-callback-return */
import { FlexColumn, FlexRow } from 'components/box';
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton';
import Image from 'components/Image';
import { useState } from 'react';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { LinkItemTEXT, MobileNavbar, NavbarWrapper, Submenu } from './styled';
import { animated, Transition } from 'react-spring';
import useResponsive from 'hooks/useResponsive';
import useLocationQuery from 'hooks/useLocationQuery';
import { BiChevronDown } from 'react-icons/bi';
import Balance from './Balance';
// import Faucet from 'feature/Faucet';

const MenuListing = [
  {
    name: 'HOME',
    key: 'home',
    path: '/',
    route: '',
  },
  {
    name: 'PLAY',
    key: 'dashboard',
    path: '/dashboard',
    route: ['dashboard'],
  },
  {
    name: 'TRANSACTION',
    key: 'transaction',
    path: null,
    route: [
      'mint',
      'breed',
      'generate',
      'energy',
      'board',
      'number',
      'airdrop',
    ],
    subMenu: [
      // { name: 'FAUCET', key: 'faucet', path: '/faucet', route: 'faucet' },
      { name: 'MINT', key: 'mint', path: '/mint', route: 'mint' },
      {
        name: 'GENERATE',
        key: 'generate',
        path: '/generate',
        route: 'generate',
      },
      { name: 'BREED', key: 'breed', path: '/breed', route: 'breed' },
      {
        name: 'ENERGY',
        key: 'energy',
        path: '/energy',
        route: 'energy',
      },
      {
        name: 'BOARD',
        key: 'setting',
        path: '/setting/board',
        route: 'setting',
      },
      {
        name: 'NUMBER',
        key: 'number',
        path: '/number',
        route: 'number',
      },
      {
        name: 'HISTORY LOGS',
        key: 'rewardlogs',
        path: '/rewardlogs',
        route: 'rewardlogs',
      },
      // {
      //   name: 'DEPOSIT',
      //   key: 'deposit',
      //   path: '/deposit',
      //   route: 'deposit',
      // },
      // {
      //   name: 'AIRDROP',
      //   key: 'airdrop',
      //   path: '/airdrop',
      //   route: 'airdrop',
      // },
    ],
  },
  // {
  //   name: 'MY NFTs',
  //   key: 'wallet',
  //   path: '/wallet',
  //   route: ['wallet', 'orderhistory'],
  // },
  // {
  //   name: 'MARKETPLACE',
  //   key: 'marketplace',
  //   path: '/marketplace',
  //   route: ['marketplace'],
  // },
];

function LinkItem({ text, link, active, onClick }) {
  return (
    <Link onClick={onClick} to={link}>
      <LinkItemTEXT active={active}>{text}</LinkItemTEXT>
    </Link>
  );
}

function LinkItemSub({ text, active, onClick, subMenu, responsive }) {
  const allPage = useLocationQuery();
  allPage.shift();
  const verifyRouteSub = (e) => {
    if (!e && allPage.length === 1) {
      return true;
    } else {
      return allPage.find((i) => i === e) ? true : false;
    }
  };

  const [showSubmenu, setShowSubmenu] = useState(false)

  return (
    <Submenu>
      <LinkItemTEXT active={active} onClick={() => setShowSubmenu(!showSubmenu)}>
        {text} <BiChevronDown />
      </LinkItemTEXT>
      <div className="sub_item_container" style={{ display: showSubmenu ? "block" : "none" }}>
        {subMenu.map((e) => (
          <Link key={e.key} onClick={onClick} to={e.path}>
            <LinkItemTEXT className="item" active={verifyRouteSub(e.route)}>
              {e.name}
            </LinkItemTEXT>
          </Link>
        ))}
      </div>
    </Submenu>
  );
}

function Navbar() {
  const responsive = useResponsive('laptop');
  const [state, setState] = useState(false);
  const [, page] = useLocationQuery();

  const verifyRoute = (e) => {
    let verify = false;
    if (!e && !page) {
      verify = true;
    } else if (e) {
      const n = e.find((i) => i === page);
      if (n) {
        verify = true;
      }
    }

    return verify;
  };

  // console.log(scroll);

  const MenuList = () => (
    <>
      {MenuListing.map((e) => {
        if (!e.subMenu) {
          return (
            <LinkItem
              onClick={() => setState(false)}
              active={verifyRoute(e.route)}
              text={e.name}
              key={e.key}
              link={e.path}
            />
          );
        } else {
          return (
            <LinkItemSub
              responsive={responsive}
              onClick={() => setState(false)}
              active={verifyRoute(e.route)}
              text={e.name}
              key={e.key}
              subMenu={e.subMenu}
            />
          );
        }
      })}
    </>
  );

  return (
    <NavbarWrapper scroll={true}>
      <Transition
        items={state}
        from={{ opacity: 0, transform: 'translateX(-100%)' }}
        enter={{ opacity: 1, transform: 'translateX(0)' }}
        leave={{ opacity: 0, transform: 'translateX(-100%)' }}
        config={{ duration: 500 }}
      >
        {({ opacity, transform }, item) =>
          item && (
            <animated.div
              style={{
                opacity: opacity,
                transform: transform,
              }}
              className="animatediv"
            >
              <MobileNavbar>
                <div onClick={() => setState(false)} className="bg_opacity" />
                <FlexColumn className="menu_mobile">
                  <FlexRow style={{ justifyContent: 'flex-start' }}>
                    <Link to={'/'}>
                      <Image
                        className={'logo'}
                        src="/image/logo/logo.svg"
                        alt="logo"
                      />
                    </Link>
                  </FlexRow>
                  <FlexColumn className="menu_item_mobile">
                    <MenuList />
                  </FlexColumn>
                </FlexColumn>
              </MobileNavbar>
            </animated.div>
          )
        }
      </Transition>

      <FlexRow
        className="toggle_btn"
        onClick={() => {
          setState(!state);
        }}
      >
        <Transition
          items={state}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          config={{ duration: 500 }}
        >
          {({ opacity }, item) =>
            item ? (
              <animated.div
                style={{
                  opacity: opacity,
                }}
              >
                <AiOutlineMenuUnfold />
              </animated.div>
            ) : (
              <animated.div
                style={{
                  opacity: opacity,
                }}
              >
                <AiOutlineMenuFold />
              </animated.div>
            )
          }
        </Transition>
      </FlexRow>
      <FlexRow className="logocontrainer">
        <Link to={'/'}>
          <Image className={'logo'} src="/image/logo/logo.svg" alt="logo" />
        </Link>
        <FlexRow className="menu_container" style={{ marginLeft: '4rem' }}>
          <MenuList />
        </FlexRow>
      </FlexRow>

      <FlexRow>
        {/* <Faucet /> */}
        <Balance />
        <ConnectWalletButton />
      </FlexRow>
    </NavbarWrapper>
  );
}

export default Navbar;
