export type PageMeta = {
  title: string;
  description?: string;
  image?: string;
};

export const DEFAULT_META: PageMeta = {
  title: '2048 Finance',
  description:
    '2048 Finance is an easy & fun puzzle Play to Earn NFT Game developed on the Binance Smart Chain. Get ready to earn $2048 tokens here',
  image: 'https:dev.2048.finance/image/landing/gamefeature-1-01.webp',
};

export const getCustomMeta = (path: string): PageMeta => {
  let basePath;
  console.log(path);
  if (path.startsWith('/setting')) {
    basePath = '/setting';
  } else if (path.startsWith('/multiplayer')) {
    basePath = '/multiplayer';
  } else if (path.startsWith('/singleplayer')) {
    basePath = '/singleplayer';
  } else if (path.startsWith('/dashboard')) {
    basePath = '/dashboard';
  } else if (path.startsWith('/marketplace')) {
    basePath = '/marketplace';
  } else if (path.startsWith('/wallet')) {
    basePath = '/wallet';
  } else if (path.startsWith('/orderhistory')) {
    basePath = '/orderhistory';
  } else if (path.startsWith('/mint')) {
    basePath = '/mint';
  } else if (path.startsWith('/breed')) {
    basePath = '/breed';
  } else if (path.startsWith('/generate')) {
    basePath = '/generate';
  } else {
    basePath = path;
  }

  switch (basePath) {
    case '/':
      return {
        title: `Home | 2048 Finance`,
      };
    case '/setting':
      return {
        title: `Setting | 2048 Finance`,
      };
    case '/multiplayer':
      return {
        title: `Multiplayer | 2048 Finance`,
      };
    case '/singleplayer':
      return {
        title: `Singleplayer | 2048 Finance`,
      };
    case '/dashboard':
      return {
        title: `Dashboard | 2048 Finance`,
      };
    case '/marketplace':
      return {
        title: `Marketplace | 2048 Finance`,
      };
    case '/wallet':
      return {
        title: `Wallet | 2048 Finance`,
      };
    case '/orderhistory':
      return {
        title: `Orderhistory | 2048 Finance`,
      };
    case '/mint':
      return {
        title: `Mint | 2048 Finance`,
      };
    case '/breed':
      return {
        title: `Breed | 2048 Finance`,
      };
    case '/generate':
      return {
        title: `Generate | 2048 Finance`,
      };
    default:
      return null;
  }
};
