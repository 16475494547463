import axios from 'axios';
import { ENV } from 'config';
import AxiosWrapper from './AxiosWrapper';
import ServerPublicService from './ServerPublicService';
import ServerService from './ServerService';

export const api = axios.create({
  headers: {
    Authorization: 'Bearer ' + window.localStorage.getItem('TOKEN_2048'),
  },
});

export const IapiPublic = axios.create();

export const apiServer = new ServerService(
  new AxiosWrapper(api, ENV.API_SINGLE_SERVER)
);

export const apiPublic = new ServerPublicService(
  new AxiosWrapper(IapiPublic, ENV.API_PUBLIC_SERVER)
);
