import { colors } from 'colors/colors';
import { GridColumn } from 'components/box';
import styled from 'styled-components';
import { device, flexAlign } from 'styling/fast';

// fix

export const LayoutWrapper = styled.div`
  /* padding-bottom: 2rem; */
  position: relative;
  .content {
    position: relative;
    min-height: calc(100vh);
    /* padding-top: 11rem; */
    margin-bottom: 2rem;
  }
`;

export const LayoutContentWrapper = styled.div<{
  noTopBg?: Boolean;
  width?: string;
}>`
  min-height: calc(100vh - 11rem);
  width: ${({ width }) => (width ? width : '100%')};
  margin: 0 auto;
  /* padding: 1.5rem 0; */
  padding: 2rem 0;
  /* BG */
  background-image: url('/image/breadcrumbs-bg.webp');
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  padding-top: 10rem;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  .container {
    padding: 2rem 5rem;
    justify-content: space-between;
    align-items: center;
  }
  .img_footer {
    width: 20rem;
    cursor: pointer;
  }
  .link_img {
    display: flex;
    ${flexAlign}
  }
  .social_contain {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .copyright {
    width: 100%;
    padding: 0 15%;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    left: 0;
  }
  .content_container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
  }
  @media ${device.mobileL} {
    .container {
      padding: 1rem;
    }
    .img_footer {
      width: 15rem;
    }
    .social_contain {
      justify-content: center;
    }
    .copyright {
      flex-direction: column;
    }
    .content_contain {
      place-items: center;
    }
    .content_container {
      grid-template-columns: 1fr;
      * {
        text-align: center;
      }
    }
  }
`;

export const LinkItemTEXT = styled.h1<{ active?: boolean }>`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ active }) => (active ? colors.PINK1 : 'white')};
  margin-right: 4rem;
  cursor: pointer;
  :hover {
    color: ${colors.PURPLE_BTN_LIGHT};
  }
  display: flex;
  ${flexAlign}
  @media ${device.laptop} {
    font-size: 4rem;
    justify-content: flex-start;
    margin: 1rem 0;
    padding-left: 3rem;
  }
`;

export const NavbarWrapper = styled.div<{ scroll: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 10rem;
  width: 100%;
  padding: 0 10rem;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 1s;
  background-color: ${({ scroll }) =>
    scroll ? '#0f0b2b' : 'rgba(22, 16, 62, 0.5)'};
  z-index: 50;

  .toggle_btn {
    color: white;
    font-size: 4rem;
    display: none;
    cursor: pointer;
  }

  .logo {
    height: 10rem;
  }
  .logocontrainer {
    @media ${device.laptop} {
      /*  */
      display: none;
      position: absolute;
      left: 10%;
    }
  }

  .animatediv {
    /* position: absolute; */
    /* transform: translateX(-50%); */

    position: fixed;
    height: 100%;
    width: 100%;
    top: 10rem;
    left: 0;
  }

  @media ${device.laptop} {
    padding: 0 1rem;
    justify-content: space-between;
    .menu_container {
      display: none;
    }
    .toggle_btn {
      display: flex;
    }
  }
`;

export const MobileNavbar = styled(GridColumn)`
  width: 100%;
  height: 100%;
  position: relative;
  .menu_mobile {
    width: 80%;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 1);
    border-right: 1rem solid ${colors.PINK1};
  }
  .menu_item_mobile {
    height: calc(100vh - 20rem);
    overflow-y: scroll;
  }
  .bg_opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export const Submenu = styled.div`
  position: relative;
  .sub_item_container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    .item {
      margin: 0;
      width: 100%;
      padding: 0.5rem 1rem;
      text-align: left;
      :hover {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }
  :hover {
    .sub_item_container {
      display: block;
    }
  }
  @media ${device.laptop} {
    .sub_item_container {
      position: relative;
      display: block;
      padding-left: 5rem;
    }
  }
`;
