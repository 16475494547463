import { lazy } from 'react';

const LazyLoading = (path: any) => {
  return lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(path), 1000);
    });
  });
};
export default LazyLoading;
