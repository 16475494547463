import React from 'react';
import styled from 'styled-components';
import Image from 'components/Image';
import { CSSTransition } from 'react-transition-group';

const LoadingWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 10rem);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  top: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
`;

function Loading() {
  return (
    <CSSTransition classNames="fade" timeout={300}>
      <LoadingWrapper>
        <Image
          width={'10rem'}
          src="/image/game/headcat-win.png"
          alt="2048-error"
        />
      </LoadingWrapper>
    </CSSTransition>
  );
}

export default Loading;
