import { colors } from 'colors/colors';
import styled from 'styled-components';
import { toneBg, toneColor } from 'styling/fast';

const StyledModalContainer = styled.div<{ tone: string }>`
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .ModalContent {
    position: relative;
  }
  .bgDark_modal {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: all 0.5s;
  }
  .container_modal {
    position: relative;
    height: auto;
    width: auto;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 2rem;
    /* padding: 2rem 0; */
  }
  .exit_btn {
    font-size: 20px;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    cursor: pointer;
    padding: 0.8rem;
    border-radius: 0.8rem;
    transition: 0.5s all;
    color: white;
    :hover {
      background-color: ${colors.GRAY_LIGHT2};
      color: ${colors.PURPLE_BTN_DARK};
    }
  }
  .title_text {
    font-size: 22px;
    color: white;
    font-weight: 500;
    padding: 2rem 3rem;
    padding-right: 10rem;
    background: ${({ tone }) => toneBg(tone)};
    border-radius: 1.5rem 1.5rem 0 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .line_modal {
    width: 100%;
    height: 3px;
    background-color: ${colors.GRAY_LIGHT2};
    margin-bottom: 1rem;
  }
  .content_modal {
    min-width: 50rem;
    border: solid 0.2rem ${({ tone }) => toneColor(tone)};
    border-radius: 0 0 2rem 2rem;
    border-top: 0;
    padding-bottom: 2rem;
  }
`;

export default StyledModalContainer;
