import Endpoint from '../Endpoint';

enum APIS {
  PATH = '/mint',
}

export default class MintEndpointed extends Endpoint {
  async minting(unit, mintType) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/${unit}/${mintType}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
