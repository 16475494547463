import { createSlice } from '@reduxjs/toolkit';
import { ProfileState } from 'state/types';

const initialState: ProfileState = {
  account: window.localStorage.getItem('ACCOUNT_2048') || '',
  token: window.localStorage.getItem('TOKEN_2048') || '',
  islogin: Boolean(window.localStorage.getItem('TOKEN_2048')),
  balance: null,
  selectedBoard: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    LOGIN: (state, action) => {
      state.account = action.payload;
      window.localStorage.setItem('ACCOUNT_2048', action.payload);
    },
    SETTOKEN: (state, action) => {
      state.token = action.payload;
      state.islogin = true;
      window.localStorage.setItem('TOKEN_2048', action.payload);
    },
    LOGOUT: (state) => {
      state.account = '';
      state.token = '';
      state.islogin = false;
      state.balance = null;
      state.selectedBoard = '';
      window.localStorage.removeItem('TOKEN_2048');
      window.localStorage.removeItem('ACCOUNT_2048');
    },
    SETISLOGIN: (state, action) => {
      state.islogin = action.payload;
    },
    SETBALANCE: (state, action) => {
      state.balance = action.payload;
    },
    SETSELECTEDBOARD: (state, action) => {
      state.selectedBoard = action.payload;
    },
  },
});
export default profileSlice.reducer;
