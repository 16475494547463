import { combineReducers } from '@reduxjs/toolkit';
import profileReducer from './profile/reducer';
import loadingReducer from './loading/reducer';
import blockReducer from './block/reducer';
import multicallReducer from './multicall/reducer';
import SettingReducer from './setting/reducer';

export default combineReducers({
  profile: profileReducer,
  refreshing: loadingReducer,
  block: blockReducer,
  multicall: multicallReducer,
  setting: SettingReducer,
});
