import { FlexRow } from 'components/box';
import React from 'react';
import { fromWei } from 'helpers/unitConversion';
import LoadingIcon from 'components/LoadingIcon';
import useBalance from 'hooks/useBalance';
import { useIsLogin } from 'state/profile/hooks';
import styled from 'styled-components';
import TokenLogo from 'components/Token';

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  .token_container {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    font-size: 1.2rem;
    .token_img {
      margin-right: 1rem;
    }
  }
`;

function Balance() {
  const isLogin = useIsLogin();
  const [balance, refreshBalance] = useBalance();

  console.log(balance)

  const balance2048 = (
    fromWei(balance?.token_2048.balance) +
    fromWei(balance?.token_2048.locked)
  )

  const balance2048E = (
    fromWei(balance?.token_2048E.balance) +
    fromWei(balance?.token_2048E.locked)
  )

  if (isLogin && !isNaN(balance2048) && !isNaN(balance2048E)) {
    return (
      <BalanceWrapper>
        <LoadingIcon
          style={{ color: 'white', marginRight: 10 }}
          state={balance}
        >
          <FlexRow className="token_container">
            <TokenLogo
              name="2048"
              className="token_img"
              width={'3rem'}
              height={'3rem'}
            />
            {(
              fromWei(balance?.token_2048.balance) +
              fromWei(balance?.token_2048.locked)
            ).toFixed(2)}
          </FlexRow>
        </LoadingIcon>
        <LoadingIcon
          style={{ color: 'white', marginRight: 10 }}
          state={balance}
        >
          <FlexRow className="token_container">
            <TokenLogo
              name="E2048"
              className="token_img"
              width={'3rem'}
              height={'3rem'}
            />

            {(
              fromWei(balance?.token_2048E.balance) +
              fromWei(balance?.token_2048E.locked)
            ).toFixed(2)}
          </FlexRow>
        </LoadingIcon>
      </BalanceWrapper>
    );
  }

  return null;
}

export default Balance;
