import { FlexRow, Text } from 'components/box';
import React from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';

const CountdownTextWrapper = styled.div`
  display: flex;
  .center_point {
    margin: 0 0.5rem;
  }
`;

function CountdownText({
  date,
  completeRender,
  style,
  className,
}: {
  date: Date;
  completeRender: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return completeRender;
    } else {
      // Render a countdown
      return (
        <CountdownTextWrapper className={className} style={style}>
          <FlexRow>
            <Text>{days}</Text>
            <Text>D</Text>
          </FlexRow>
          <Text className="center_point">:</Text>
          <FlexRow>
            <Text>{hours}</Text>
            <Text>H</Text>
          </FlexRow>
          <Text className="center_point">:</Text>
          <FlexRow>
            <Text>{minutes}</Text>
            <Text>M</Text>
          </FlexRow>
          <Text className="center_point">:</Text>
          <FlexRow>
            <Text>{seconds}</Text>
            <Text>S</Text>
          </FlexRow>
        </CountdownTextWrapper>
      );
    }
  };

  return <Countdown date={date} renderer={renderer} />;
}

export default CountdownText;
