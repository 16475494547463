import { createSlice } from '@reduxjs/toolkit';
import { SettingState } from 'state/types';

const initialState: SettingState = {
  slippage: 0.1,
};

export const SettingSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    SETSLIPPAGE: (state, action) => {
      state.slippage = action.payload;
    },
  },
});

export const { SETSLIPPAGE } = SettingSlice.actions;

export default SettingSlice.reducer;
