import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { useEffect, useRef } from 'react';
import { AppState, useAppDispatch, useAppSelector } from 'state';
import { simpleRpcProvider } from 'utils/providers';
import { setBlock } from './action';

export const usePollBlockNumber = () => {
  const timer = useRef(null);
  const dispatch = useAppDispatch();
  const isWindowVisible = useIsWindowVisible();

  useEffect(() => {
    if (isWindowVisible) {
      timer.current = setInterval(async () => {
        const blockNumber = await simpleRpcProvider.getBlockNumber();
        dispatch(setBlock(blockNumber));
      }, 6000);
    } else {
      clearInterval(timer.current);
    }

    return () => clearInterval(timer.current);
  }, [dispatch, timer, isWindowVisible]);
};

export const useBlock = () => {
  return useAppSelector((state: AppState) => state.block.currentBlock);
};

export const useInitialBlock = () => {
  return useAppSelector((state: AppState) => state.block.initialBlock);
};
