import { GridColumn, Text } from 'components/box';
import Button from 'components/Button';
import { ButtonActive } from 'components/ButtonActive/ButtonActive';
import InputText from 'components/InputText/InputText';
// import Slippage from 'components/Transaction/Slippage';
import React, { useState } from 'react';
import styled from 'styled-components';

const BuySellModalWrapper = styled.div`
  min-width: 50rem;
  padding: 2rem;
  .detail {
    border: 1px solid white;
    border-radius: 1rem;
    padding: 1rem 4rem;
  }
`;

interface Props {
  tone?: string;
  onClose?: () => any;
}

function SellEnergyModal({ tone }: Props) {
  const price = [0.5, 0.75, 1, 5];
  const [select, setPrice] = useState(0.5);
  const [amount, setAmout] = useState('');
  return (
    <BuySellModalWrapper>
      <GridColumn gap="0.5rem">
        <GridColumn>
          <Text size="lg">Select price to sell</Text>
          <GridColumn style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
            {price.map((e) => (
              <ButtonActive
                key={`price-${e}`}
                tone={tone}
                active={select === e}
                onClick={() => {
                  setPrice(e);
                }}
                style={{ width: '10rem', height: '4rem', color: 'white' }}
              >
                {e}
              </ButtonActive>
            ))}
          </GridColumn>
          <Text size="lg">Enter amount to sell</Text>
        </GridColumn>
        <InputText
          value={amount}
          onChange={(e) => setAmout(e.target.value)}
          max={10}
          tone={tone}
          type="number"
        />
        {/* <TextH2>slippage tolerance</TextH2> */}
        {/* <Slippage tone={tone} />
        <GridColumn className="detail" gap="0.1rem">
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Text size="base">Price</Text>
            <Text size="base">??</Text>
          </FlexRow>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Text size="base">Price Impact</Text>
            <Text size="base">??</Text>
          </FlexRow>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Text size="base">You will Recieve</Text>
            <Text size="base">??</Text>
          </FlexRow>
        </GridColumn> */}
        <Button disabled={false} text={'SELL ENERGY'} tone={tone} />
      </GridColumn>
    </BuySellModalWrapper>
  );
}

export default SellEnergyModal;
