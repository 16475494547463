import React, { useEffect, useCallback, useState, useContext } from 'react';
import { useAccount, useConfig } from 'wagmi';
import { readContracts } from '@wagmi/core'
import useGameSeason from './useGameSeason';
import GameViewABI from 'abi/GameViewABI';
import { formatEther, parseEther } from 'viem';
import { fetchBoardAttr, fetchBoardNumbers, fetchEnergyGeneratorAttr, fetchNumberGeneratorAttr, fetchNumberGeneratorUpgradeCost } from './fetchNFTAttributes';
import { mean } from 'lodash';

const BalanceContext = React.createContext<[ WalletBalance | null, () => Promise<void>, boolean ]>([
  null, async () => {}, true,
])

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export function averageBoardNumber(numbers: number[]) {
  const sum = numbers.reduce((acc, curr, i) => acc + curr * (1 << i), 0)
  if (sum == 0) return 0;
  console.log(sum)
  return sum / numbers.reduce((acc, curr) => acc + curr, 0)
}

function useBalanceLogic(): [ WalletBalance, () => Promise<void>, boolean ] {
  const { address } = useAccount()
  const config = useConfig()

  const { data: gameSeason, isFetching: gameSeasonFetching } = useGameSeason()
  const [tokenBalances, setTokenBalances] = useState<WalletBalance>(null);
  const [isTokenBalancesFetching, setIsTokenBalancesFetching] = useState(true);

  const fetchTokenBalances = useCallback(async (waitTime = 2000) => {
    setIsTokenBalancesFetching(true);

    await wait(waitTime)

    console.log('REFETCH BALANCE')

    const tokenBalances: WalletBalance = {
      walletAddress: address,
      token_2048: {
        walletAddress: address,
        tokenAddress: gameSeason.tokens.token2048,
        balance: "0",
        pendingReward: "0",
        locked: "0",
        wallet: "0",
      },
      token_2048E: {
        walletAddress: address,
        tokenAddress: gameSeason.tokens.token2048E,
        balance: "0",
        pendingReward: "0",
        locked: "0",
        wallet: "0",
      },
      token_BUSD: {
        walletAddress: address,
        tokenAddress: gameSeason.tokens.token2048,
        balance: "0",
        pendingReward: "0",
        locked: "0",
        wallet: "0",
      },
      nft_BOARD: [],
      nft_NUMBER_GENERATOR: [],
      nft_ENERGY_WORKER: [],
      nft_NUMBER: {
        1: 0,
        2: 0,
        4: 0,
        8: 0,
        16: 0,
        32: 0,
        64: 0,
        128: 0,
        256: 0,
        512: 0,
        1024: 0,
        2048: 0,
      },
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_INDEXER}/balances/${address}`
      );
      const data = await response.json();

      // Decode ERC20 balances
      const token2048 = data.erc20.find(x => x.token_address == gameSeason.tokens.token2048)
      const token2048E = data.erc20.find(x => x.token_address == gameSeason.tokens.token2048E)

      tokenBalances.token_2048.balance = parseEther(token2048?.balance.toFixed(18) || '0').toString()
      tokenBalances.token_2048.wallet = parseEther(token2048?.balance.toFixed(18) || '0').toString()

      tokenBalances.token_2048E.balance = parseEther(token2048E?.balance.toFixed(18) || '0').toString()
      tokenBalances.token_2048E.wallet = parseEther(token2048E?.balance.toFixed(18) || '0').toString()

      // Decode ERC721 balances
      const tokenBoard = data.erc721.filter(x => x.token_address == gameSeason.tokens.tokenBoard)
      const tokenNumberGenerator = data.erc721.filter(x => x.token_address == gameSeason.tokens.tokenNumberGen)
      const tokenEnergyWorker = data.erc721.filter(x => x.token_address == gameSeason.tokens.tokenEnergyGen)

      for (const board of tokenBoard) {
        tokenBalances.nft_BOARD.push({
          score: 0,
          played: 0,
          numbers: [],
          rechargeAt: "2024-05-04T03:30:52.131Z",
          listPrice: 0,
          boardId: board.token_id.toString(),
          star: 0,
          average: 0,
        })
      }

      for (const numberGenerator of tokenNumberGenerator) {
        tokenBalances.nft_NUMBER_GENERATOR.push({
          rate: 0,
          generated: 0,
          rechargeAt: "2024-05-04T03:30:52.157Z",
          listPrice: 0,
          tokenId: numberGenerator.token_id.toString(),
          star: 0,
          upgradeCost: 0,
        },)
      }

      for (const energyWorker of tokenEnergyWorker) {
        tokenBalances.nft_ENERGY_WORKER.push({
          rate: 0,
          generated: 0,
          energyPrice: 0,
          rechargeAt: "2024-05-04T03:30:52.160Z",
          listPrice: 0,
          tokenId: energyWorker.token_id.toString(),
          star: 0,
          remaining: 0,
        })
      }

      // Decode ERC1155 balances
      const tokenNumber = data.erc1155.filter(x => x.token_address == gameSeason.tokens.tokenNumber)

      for (const number of tokenNumber) {
        tokenBalances.nft_NUMBER[number.token_id] = number.balance
      }

      // Fetch NFT Attributes
      const boardAttr = await fetchBoardAttr(config, tokenBalances.nft_BOARD.map(x => x.boardId))
      // const boardNumbers = await fetchBoardNumbers(config, tokenBalances.nft_BOARD.map(x => x.boardId))
      const numberGeneratorAttr = await fetchNumberGeneratorAttr(config, tokenBalances.nft_NUMBER_GENERATOR.map(x => x.tokenId))
      // const numberGeneratorUpgradeCost = await fetchNumberGeneratorUpgradeCost(config, tokenBalances.nft_NUMBER_GENERATOR.map(x => x.tokenId))
      const energyGeneratorAttr = await fetchEnergyGeneratorAttr(config, tokenBalances.nft_ENERGY_WORKER.map(x => x.tokenId))
      // console.log('Board Attr', boardAttr)
      // console.log('Board Numbers', boardNumbers)
      console.log('Number Generator Attr', numberGeneratorAttr)
      // console.log('Number Generator Up', numberGeneratorUpgradeCost)
      // console.log('Energy Generator Attr', energyGeneratorAttr)

      for (let i = 0; i < tokenBalances.nft_BOARD.length; i++) {
        const board = tokenBalances.nft_BOARD[i]
        board.star = Number(boardAttr[i].result[0])
        board.played = Number(boardAttr[i].result[1])
        board.numbers = boardAttr[i].result[2].map(Number)
        board.score = (boardAttr[i].result[2] as bigint[]).reduce((acc, curr, i) => acc + Number(curr) * (1 << i) * (1 << i), 0)
        board.average = averageBoardNumber(board.numbers) || 0
      }

      for (let i = 0; i < tokenBalances.nft_NUMBER_GENERATOR.length; i++) {
        const numberGenerator = tokenBalances.nft_NUMBER_GENERATOR[i]
        numberGenerator.star = Number(numberGeneratorAttr[i].result[0])
        numberGenerator.rate = Number(numberGeneratorAttr[i].result[1])
        numberGenerator.upgradeCost = parseFloat(formatEther(numberGeneratorAttr[i].result[2]))
        numberGenerator.generated = Number(numberGeneratorAttr[i].result[3])
      }

      for (let i = 0; i < tokenBalances.nft_ENERGY_WORKER.length; i++) {
        const energyWorker = tokenBalances.nft_ENERGY_WORKER[i]
        energyWorker.star = Number(energyGeneratorAttr[i].result[0])
        energyWorker.generated = Number(energyGeneratorAttr[i].result[1])
        energyWorker.rate = Number(energyGeneratorAttr[i].result[2])
        energyWorker.remaining = energyWorker.rate - energyWorker.generated
      }

      setTokenBalances(tokenBalances);
    } catch (error) {
      console.error('Error fetching token balances:', error);
    } finally {
      setIsTokenBalancesFetching(false);
    }
  }, [address, gameSeason]);

  useEffect(() => {
    if (gameSeason && !gameSeasonFetching) {
      fetchTokenBalances(0);
    }
  }, [fetchTokenBalances, address, gameSeason, gameSeasonFetching]);

  return [ tokenBalances, fetchTokenBalances, isTokenBalancesFetching ]
}

export default function useBalance(): [ WalletBalance, () => Promise<void>, boolean ] {
  return useContext(BalanceContext)
}

export function BalanceProvider({ children }) {
  const balance = useBalanceLogic()

  return (
    <BalanceContext.Provider value={balance}>
      {children}
    </BalanceContext.Provider>
  )
}
